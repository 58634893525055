<template>
  <div class="tw-flex">
    <div class="tw-flex tw--mt-3 md:tw--mt-4">
      <v-sheet class="tw-mr-2">
        <v-toolbar color="primary" class="tw-rounded">
          <img
            v-if="isImage"
            class="tw-w-6 tw-h-6 md:tw-w-8 md:tw-h-8"
            :src="icon"
            alt="icon"
          />
          <v-icon v-else color="white" large>{{ icon }}</v-icon>
        </v-toolbar>
      </v-sheet>
    </div>
    <h2 class="tw-text-xl md:tw-text-2xl tw-mt-2"><slot></slot></h2>
  </div>
</template>

<script>
export default {
  name: 'CardTitle',
  props: {
    icon: {
      type: String,
      required: true,
    },
    isImage: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
