import moment from 'moment'

export const formatToHuman = (date) => {
  return moment(date).format('DD/MM/YYYY')
}

export const formatToHumanWithTime = (date) => {
  if (!date || date == '~') return '~'
  return moment(date).format('DD/MM/YYYY hh:mma')
}

export const getFromAndToDate = () => {
  const to = moment().format('YYYY-MM-DD')
  const from = moment().subtract(1, 'months').format('YYYY-MM-DD')
  return { from, to }
}
