import axios from 'axios'
import _ from 'lodash'
import store from '@/store'
//import sups from '../../tests/unit/__mocks__/assessment-center/supplementaryMock.json'

export default {
  data: () => ({
    search: '',
    pagination: {
      pageNumber: 1,
      perPage: 10,
      totalItems: 0,
      pageCount: 0,
    },
    recallTicketDialog: false,
    recallLoading: false,
    tab: 'pendingTickets',
    headers: [
      {
        text: 'No.',
        align: 'start',
        sortable: true,
        value: 'id',
      },
      {
        text: 'Customer',
        align: 'start',
        sortable: false,
        value: 'clientInfo.name',
      },
      {
        text: 'Phone Number',
        align: 'start',
        sortable: false,
        value: 'clientInfo.phoneNumber',
      },
      {
        text: 'Date',
        align: 'start',
        sortable: true,
        value: 'createdAt',
      },
      {
        text: 'Reg No.',
        align: 'start',
        value: 'vehicleInfo.registrationNumber',
      },
      { text: 'Status', value: 'status', sortable: true },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    supplementaryHeaders:[
      {
        text: 'No.',
        align: 'start',
        sortable: true,
        value: 'bookingId',
      },
     {
       text: 'Claim Number',
       align: 'start',
       sortable: false,
       value: 'biddingForm.claimNumber',
     },
      {
        text: 'Make',
        align: 'start',
        sortable: false,
        value: 'biddingForm.make',
      },
      {
        text: 'Model',
        align: 'start',
        sortable: false,
        value: 'biddingForm.model',
      },
      {
        text: 'Body Type',
        align: 'start',
        sortable: false,
        value: 'biddingForm.bodyType',
      },
      {
        text: 'Date',
        align: 'start',
        sortable: true,
        value: 'createdAt',
      },
      { text: 'Status', align:'center', value: 'status', sortable: true },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    reinspectionHeaders:[
      {
        text: 'No.',
        align: 'start',
        sortable: true,
        value: 'bookingId',
      },
     {
       text: 'Customer',
       align: 'start',
       sortable: false,
       value: 'clientInfo.name',
     },
      {
        text: 'Make',
        align: 'start',
        sortable: false,
        value: 'biddingForm.make',
      },
      {
        text: 'Model',
        align: 'start',
        sortable: false,
        value: 'biddingForm.model',
      },
      {
        text: 'Reg No.',
        align: 'start',
        sortable: false,
        value: 'biddingForm.bodyType',
      },
      {
        text: 'Date',
        align: 'start',
        sortable: true,
        value: 'createdAt',
      },
      {
        text: 'Phone No.',
        align: 'start',
        sortable: false,
        value: 'biddingForm.bodyType',
      },
      { text: 'Status', align:'center', value: 'status', sortable: true },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    sortBy: ['id', 'createdAt', 'vehicleInfo.registrationNumber', 'status'],
    sortDesc: [],
    sortByQuery: '',
    fetchCheckout: false,
  }),
  watch: {
    'pagination.perPage': function () {
      if(this.isSupplementary) { this.fetchSupplementaries()}
      else if(this.isReinspection) { this.fetchReinspections()}
      else this.fetchBookings()
    },

    'pagination.pageNumber': function () {
      if(this.isSupplementary) { this.fetchSupplementaries()}
      else if(this.isReinspection) { this.fetchReinspections()}
      else this.fetchBookings()
    },

    search: function () {
      this.initiateSearch()
    },

    sortBy: function () {
      this.sortByQuery = ''
      if (this.sortBy[0]) {
        let sort = this.sortDesc[0] === true ? 'desc' : 'asc'
        if (this.sortBy[0] === 'id') this.sortByQuery = `id,${sort}`
        else if (this.sortBy[0] === 'status')
          this.sortByQuery = `status,${sort}`
        else if (this.sortBy[0] === 'createdAt')
          this.sortByQuery = `createdAt,${sort}`
        else if (this.sortBy[0] === 'vehicleInfo.registrationNumber')
          this.sortByQuery = `vehicleInfo,${sort}`
      }
      this.fetchBookings()
    },
  },
  methods: {
    initiateSearch: _.debounce(function () {
      setTimeout(
        function () {
          if(this.isSupplementary) { this.fetchSupplementaries()}
          else if(this.isReinspection) { this.fetchReinspections()}
          else this.fetchBookings()
        }.bind(this),
        1000
      )
    }, 500),

    fetchBookings: function () {
      this.loadingTable = true
      const bookingsUrl = `${this.fetchBookingsUrl}&pageNumber=${this.pagination.pageNumber}&perPage=${this.pagination.perPage}
      &search=${this.search}&sort=${this.sortByQuery}`
      const checkoutUrl = `${this.fetchBookingsUrl}?index=${this.pagination.pageNumber}&size=${this.pagination.perPage}&searchText=${this.search}`
      axios
        .get( this.fetchCheckout ? checkoutUrl : bookingsUrl  , 
          {
            headers: {
              Region: store.getters['auth/authenticatedUser']['custom:region'],
            },
          }
        )
        .then((res) => {
          this.bookings = this.fetchCheckout  ? this.scanObject(res, 'data.data', []) : this.scanObject(res, 'data.data.content', [])
          this.loadingTable = false
          this.pagination.pageCount = this.fetchCheckout ? this.bookings.length : this.scanObject(
            res,
            'data.data.totalPages',
            0
          )
          this.pagination.totalItems = this.fetchCheckout ?  this.bookings.length + 1 : this.scanObject(
            res,
            'data.data.totalElements',
            0
          )
        })
        .catch(() => {
          this.loadingTable = false
        })
    },

    fetchSupplementaries: function () {
      this.loadingTable = true
      const supplementariesUrl = `${this.fetchSupplementariesUrl}?index=${this.pagination.pageNumber}&size=${this.pagination.perPage}&searchText=${this.search}`
      axios
        .get( supplementariesUrl  , 
          {
            headers: {
              Region: store.getters['auth/authenticatedUser']['custom:region'],
            },
          }
        )
        .then((res) => {
          this.supplementaryBooking =  this.scanObject(res, 'data.data', [])
          this.loadingTable = false
          this.pagination.pageCount = this.supplementaryBooking.length ?? 0
          this.pagination.totalItems = this.supplementaryBooking.length ? this.supplementaryBooking.length + 1  : 0
        })
        .catch(() => {
          this.loadingTable = false
        })
    },

    fetchReinspections: function () {
      this.loadingTable = true
      const reinspectionUrl = `${this.fetchReinspectionsUrl}?pageNumber=${this.pagination.pageNumber}&size=${this.pagination.perPage}&bookingIdSearchText=${this.search}`
      axios
      .get( reinspectionUrl)
      .then((res) => {
        this.reinspectionBookings =  this.scanObject(res, 'data.data', [])
        this.loadingTable = false
        this.pagination.pageCount = this.reinspectionBookings.length ?? 0
        this.pagination.totalItems = this.reinspectionBookings.length ? this.reinspectionBookings.length + 1  : 0
      })
      .catch(() => {
        this.loadingTable = false
      })
        this.loadingTable = false
    },

    recallTicket(userType) {
      this.recallLoading = true
      axios
        .post(
          `/motor-assessment/api/booking/recall-assignment/${this.bookingId}/${userType}`
        )
        .then(() => {
          this.$toasted.success('Ticket recalled successfully', {
            duration: 3000,
          })
          this.recallLoading = false
          this.recallTicketDialog = false
          this.fetchBookings()
        })
        .catch(() => {
          this.$toasted.error('An error occurred, try again', {
            duration: 3000,
          })
          this.recallLoading = false
          this.recallTicketDialog = false
        })
    },
  },
}
